import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

//utils
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import {
  GiGrapes,
  GiCube,
  GiSlicedBread,
  GiTruck,
  GiSatelliteCommunication,
} from "react-icons/gi";

import Mapa from "./MapaConsulta";

import "../style/style_pag.css";

import api from "../api";

import axios from "axios";
import { ApiTracerVeiculos } from "../service/authtracer";

const useStyles = makeStyles({
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",
    alignItems: "center",
  },
  cabecalhos: {
    color: "black",
    fontSize: 12,
    fontWeight: 750,
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#b71c1c",
    color: "#b71c1c",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default function Dashtabelaveiculostransito({ datafiltra, operacoes }) {
  const classes = useStyles();

  const [pontoreferencia, setPontoreferencia] = useState();
  const [veiculostracer, setVeiculostracer] = useState();
  const [veiculostransito, setVeiculostransito] = useState(1);
  const [origem, setOrigem] = useState();
  const [posicaomapa, setPosicaomapa] = useState([]);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (i) => {
    setOpen(true);
    setPosicaomapa(i);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function geolocalizacao(lat1, lon1, lat2, lon2) {
    // distance between latitudes
    // and longitudes
    let dLat = ((lat2 - lat1) * Math.PI) / 180.0;
    let dLon = ((lon2 - lon1) * Math.PI) / 180.0;

    // converte em radiano
    lat1 = (lat1 * Math.PI) / 180.0;
    lat2 = (lat2 * Math.PI) / 180.0;

    // aplica a formula
    let a =
      Math.pow(Math.sin(dLat / 2), 2) +
      Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
    let rad = 6371000; //circoferencia aproximada da terra, se querer passar para km cortar tres 0.
    let c = 2 * Math.asin(Math.sqrt(a));
    return rad * c;
  }

  //tracer
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        let dados = carg.map((item) => [
          item.name,
          item.lat,
          item.lng,
          item.speed,
        ]);

        setVeiculostracer(dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data[0].items;

          let dados = carg.map((item) => [
            item.name,
            item.lat,
            item.lng,
            item.speed,
          ]);

          setVeiculostracer(dados);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  //ponto referencia
  useEffect(() => {
    api
      .get("/pontoreferencias")
      .then((res) => {
        let ponto = res.data;

        setPontoreferencia(ponto);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/pontoreferencias")
        .then((res) => {
          let ponto = res.data;

          setPontoreferencia(ponto);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  //veiculos em transito
  useEffect(() => {
    api
      .get("/dashFrotaVeiculoretorno")
      .then((res) => {
        let dados = res.data;

        setVeiculostransito(dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //busca automatico
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashFrotaVeiculoretorno")
        .then((res) => {
          let dados = res.data;
          let n = 60;

          setVeiculostransito(dados);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  //incluir origem
  useEffect(() => {
    api
      .get("/origem")
      .then((res) => {
        let carg = res.data;

        let filterCerca = carg.filter((x) => x.cerca !== null);

        setOrigem(filterCerca);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function metodoPrincipal() {
    if (veiculostransito !== 1) {
      let Op = Number(operacoes);
      let localcoleta = origem;
      let idponto = pontoreferencia;
      let placas = veiculostracer;
      let cargas = veiculostransito;
      let placasloja = [];
      let placasnoraio = [];
      let placasfora = [];

      //filtra a operação
      let filterorigem = localcoleta?.filter((x) =>
        x.Cad_operacoes.some((i) => i.id === Op)
      );

      placas?.forEach((veiculo) => {
        let dentro = false;

        idponto?.forEach((ponto) => {
          let distancia = geolocalizacao(
            veiculo[1],
            veiculo[2],
            ponto.latitude,
            ponto.longitude
          );

          if (distancia <= ponto.cerca) {
            filterorigem.forEach((origem) => {
              let distanciaOrigem = geolocalizacao(
                veiculo[1],
                veiculo[2],
                origem.latitude,
                origem.longitude
              );

              placasnoraio.push({
                idponto: ponto.id,
                cidade: ponto.cidade,
                ponto: ponto.pontos,
                placa: veiculo[0],
                temperature: veiculo.sensor ? veiculo.sensor.temperature : "0",
                velocidade: veiculo[3],
                posicao: [veiculo[1], veiculo[2]],
                israio: true,
                distancia: distanciaOrigem,
                descricaoOrigem: origem.descricao,
              });
            });

            dentro = true;
          }
        });

        if (!dentro) {
          placasfora.push({
            idponto: "Buscando localizacao....",
            cidade: "Pesquisando Cidade...",
            ponto: `${veiculo[1]},${veiculo[2]}`,
            placa: veiculo[0],
            temperature: veiculo.sensor ? veiculo.sensor.temperature : "0",
            velocidade: veiculo[3],
            posicao: [veiculo[1], veiculo[2]],
            israio: false,
            distancia: 9999999999,
            descricaoOrigem: 0,
          });
        }
      });

      let placastransito = [...placasnoraio, ...placasfora];
      let novoArray = [];

      placastransito.map((item) =>
        cargas?.map((x) => {
          if (x.fkplaca.placa == item.placa) {
            novoArray.push(Object.assign(item, x));
          }
        })
      );

      //funcao para ordenar a lista por hora
      const itens = novoArray.sort(function (a, b) {
        var c = a.distancia;
        var d = b.distancia;
        return c - d; //data mais antiga para mais nova
      });

      // Filtrando as viagens com base nos IDs de operações do usuário
      const filteredViagens = itens?.filter((v) => {
        // Obtendo todos os IDs de operações associadas à origem da viagem
        const origemOperacoesIds = v.fkviagens.cviagens?.flatMap((c) =>
          c.fkorigem.Cad_operacoes?.map((o) => o.id)
        );

        // Verificando se algum dos IDs de operações da origem está presente nos IDs de operações do usuário
        return origemOperacoesIds.some((id) => id === Op);
      });

      const newArray = filteredViagens.reduce((acc, item) => {
        let existing = acc.find((e) => e.placa === item.placa);
        if (!existing) {
          existing = {
            idponto: item.idponto,
            cidade: item.cidade,
            ponto: item.ponto,
            placa: item.placa,
            posicao: item.posicao,
            israio: item.israio,
            velocidade: item.velocidade,
            descricaodasorigens: [],
            placacavalo: item.fkviagens.fkveiculotrator.placa,
            placacarreta: item.fkplaca.nfrota,
          };
          acc.push(existing);
        }

        // Verificar se a descricaoOrigem já existe antes de adicionar
        const descricaoJaExiste = existing.descricaodasorigens.some(
          (origem) => origem.descricaoOrigem === item.descricaoOrigem
        );

        if (!descricaoJaExiste) {
          existing.descricaodasorigens.push({
            distancia: item.distancia,
            descricaoOrigem: item.descricaoOrigem,
          });
        }

        return acc;
      }, []);
      return newArray;
    } else {
      console.log("teste");
    }
  }
  const dados = metodoPrincipal();

  function Count() {
    let d = dados;

    return d?.length;
  }

  let contar = Count();

  setInterval(dados, 5000);

  return (
    <Card
      style={{
        background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
      }}
    >
      <div
        style={{
          background: "#5C677D",
          flexFlow: "nowrap",
          justifyContent: "flex-end",
          color: "white",
          font: "monospace",
          display: "flex",
        }}
      >
        <Typography
          style={{
            height: 35,
            marginRight: 180,
            fontSize: 20,
          }}
        >
          Veiculos em Retorno
        </Typography>
        <Typography
          style={{
            fontSize: 22,
            marginRight: 10,
            marginTop: 5,
          }}
        >
          Total:
        </Typography>

        <Typography
          style={{
            marginRight: 10,
            fontSize: 22,
            marginTop: 5,
          }}
        >
          {contar == 0 ? 0 : contar}
        </Typography>
      </div>
      <TableContainer className={classes.root} sx={{ maxHeight: 189 }}>
        <Table
          sx={{
            //  width: '100%',
            //  maxWidth: 60,
            bgcolor: "background.paper",
            //  position: 'relative',
            overflow: "auto",
            maxHeight: 100,
            //   minHeight: 210,
            minWidth: 140,
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={classes.cabecalhos}>Cavalo</Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title="Unidade de Carga">
                  <Typography className={classes.cabecalhos}>U.C</Typography>
                </Tooltip>
              </TableCell>

              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Cidade</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Local</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>
                  Distancia(km)
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dados?.map((item) => (
              <TableRow>
                <TableCell component="th" scope="row">
                  {item.velocidade <= 0 ? (
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      variant="dot"
                    >
                      <Typography
                        className="textoareaplaca"
                        style={{
                          fontSize: 15,
                        }}
                      >
                        {item.placacavalo}
                      </Typography>
                    </StyledBadge>
                  ) : (
                    <Typography
                      className="textoareaplaca"
                      style={{
                        fontSize: 15,
                      }}
                    >
                      {item.placacavalo}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="center">
                  <Typography
                    className="textoareanfrota"
                    style={{ fontSize: 15 }}
                  >
                    {item.placacarreta}
                  </Typography>
                </TableCell>

                <TableCell align="left">
                  <Typography>{item.cidade}</Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography style={{ minWidth: 120 }}>
                    <Button onClick={() => handleClickOpen(item.posicao)}>
                      <LocationOnIcon
                        style={{
                          fontSize: 15,
                          color: "red",
                        }}
                      />
                    </Button>
                    <Dialog
                      fullWidth
                      maxWidth="lg"
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">
                        latitude e longitude : {item.posicao}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <Mapa
                            transito={posicaomapa}
                            operacoesMapa={operacoes}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Fechar</Button>
                      </DialogActions>
                    </Dialog>
                    {item.israio === false ? (
                      <div style={{ marginLeft: 70 }}>
                        <GiSatelliteCommunication className="containersatelite" />
                      </div>
                    ) : (
                      item.ponto
                    )}
                  </Typography>
                </TableCell>

                {item.descricaodasorigens.map((x) => (
                  <TableCell style={{ padding: 1 }}>
                    {(() => {
                      let distancia = parseInt(x.distancia);
                      let calculo =
                        distancia === 9999999999 ? 0 : (distancia * 1) / 1000;
                      let descricao = x.descricaoOrigem;

                      return (
                        <>
                          <Typography
                            style={{
                              backgroundColor: "#415A77",
                              fontFamily: "serif",
                              color: "#fff",
                              borderColor: "#778DA9",
                              borderRadius: "4px",
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                              //  justifyContent: "space-between", // Distribui espaço entre itens
                              textAlign: "center",
                              minWidth: 90,
                            }}
                          >
                            {descricao === 0 ? "Loading" : descricao}
                          </Typography>
                          <Typography
                            style={{
                              // backgroundColor: "#EDF1F6",
                              fontFamily: "serif",
                              //  color: "#fff",
                              borderColor: "#778DA9",
                              borderRadius: "4px",
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                              //  justifyContent: "space-between", // Distribui espaço entre itens
                              textAlign: "center",
                              minWidth: 90,
                            }}
                          >
                            {calculo}
                          </Typography>
                        </>
                      );
                    })()}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
