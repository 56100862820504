import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@mui/material/Tooltip";

import { GiGrapes, GiCube, GiSlicedBread } from "react-icons/gi";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import { FaTemperatureLow } from "react-icons/fa";

//utils
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "moment-duration-format";

import apitracer from "../apitracer";
import api from "../api";
import "../style/style_pag.css";
import axios from "axios";
import { ApiTracerVeiculos } from "../service/authtracer";
import Progress_bar from "./Progress_bar";
import Box from "@mui/material/Box";

const useStyles = makeStyles({
  title: {
    fontSize: 14,
    marginTop: -9,
    //  marginLeft: 40,
    color: "#ffff",
    alignItems: "center",
  },
  cabecalhos: {
    color: "black",
    fontSize: 12,
    fontWeight: 750,
    marginLeft: -15,
  },
});

export default function Dashtabelaveiculoslojas({
  datafiltra,
  operacoes,
  gridorigem,
}) {
  const classes = useStyles();
  const [carreta, setCarreta] = useState(1);
  const [veiculostracer, setVeiculostracer] = useState();

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
      } else {
        const diff = Math.random() * 8;

        setProgress(progress + diff);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  //incluir veiculo
  useEffect(() => {
    api
      .get("/dashFrotaveiculosLojaControllers")
      .then((res) => {
        let carg = res.data;
        let nulo = null;
        let dados = carg.filter(
          (item) => item.fkstatusentregas.fkviagens !== nulo
        );

        //funcao para ordenar a lista por hora
        const itens = dados.sort(function (a, b) {
          var c = new Date(a.dtchegadaloja).getTime();
          var d = new Date(b.dtchegadaloja).getTime();
          return c - d; //data mais antiga para mais nova
        });
        setCarreta(itens);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log("ddd", carreta);
  useEffect(() => {
    const interval = setInterval(() => {
      api
        .get("/dashFrotaveiculosLojaControllers")
        .then((res) => {
          let carg = res.data;
          let nulo = null;

          let dados = carg.filter(
            (item) => item.fkstatusentregas.fkviagens !== nulo
          );

          //funcao para ordenar a lista por hora
          const itens = dados.sort(function (a, b) {
            var c = new Date(a.dtchegadaloja).getTime();
            var d = new Date(b.dtchegadaloja).getTime();
            return c - d; //data mais antiga para mais nova
          });
          setCarreta(itens);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  //incluir veiculo
  useEffect(() => {
    axios
      .get(ApiTracerVeiculos)
      .then((res) => {
        let carg = res.data[0].items;

        //let distancia = geolocalizacao(latcd, loncd, lat2, lon2)
        //let teste = data.map((item) => 'Placa:'+item.name +  ' longitude:' + item.lng  +' latitude:' + item.lat )
        let dados = carg.map(function (x) {
          return {
            placa: x.name,
            temperatura: x.temperature,
          };
        });

        setVeiculostracer(dados);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      axios
        .get(ApiTracerVeiculos)
        .then((res) => {
          let carg = res.data.data;

          let dados = carg.map(function (x) {
            return {
              placa: x.name,
              temperatura: x.temperature,
            };
          });

          setVeiculostracer(dados);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  function MetodoPrincipal() {
    if (carreta !== 1) {
      let Op = Number(operacoes);

      let novoArray = [];

      let placastracer = veiculostracer;

      let veiculosloja = carreta?.filter((item) => {
        // Se origem for undefined ou 999, retorna todos os itens
        if (gridorigem === undefined || Number(gridorigem) === 999) {
          return true;
        }
        // Caso contrário, filtra pelo valor de origem dentro de fkentregas
        return item.fkentregas.origem === Number(gridorigem);
      });

      veiculosloja?.map((item) =>
        placastracer?.map((x) => {
          if (x.placa == item.fkstatusentregas.fkviagens.fkveiculo.placa) {
            novoArray.push(Object.assign(item, x));
          }
        })
      );

      const filteredViagens = novoArray?.filter((v) => {
        // Obtendo todos os IDs de operações associadas à origem da viagem
        const origemOperacoesIds = v.fkentregas?.fkorigem?.Cad_operacoes?.map(
          (o) => o.id
        );

        // Verificando se algum dos IDs de operações da origem está presente nos IDs de operações do usuário
        return origemOperacoesIds?.some((id) => id === Op);
      });
      return filteredViagens;
    } else {
      console.log("teste");
    }
  }
  let metodoprincipal = MetodoPrincipal();

  return (
    <Card
      style={{
        //background: "#191E4D",
        justifyItems: "flex-start",
        justifyContent: "space-around",
        border: " 2px solid  #F4F6F9",
        borderRadius: 11,
      }}
    >
      <div
        style={{
          backgroundColor: "red",
          direction: "inherit",
        }}
      >
        <Typography
          style={{
            background: "#7D8597",
            justifyItems: "flex-start",
            justifyContent: "space-around",
            color: "white",
            font: "monospace",
            height: 35,
            fontSize: 20,
          }}
        >
          Veiculos em lojas
        </Typography>
      </div>
      {(() => {
        if (carreta == 1) {
          return (
            <Box
              sx={{
                width: "100%",
                height: 50,
              }}
            >
              <Progress_bar bgcolor="#191E4D" progress={progress} height={4} />
            </Box>
          );
        }
      })()}
      <TableContainer className={classes.root} sx={{ maxHeight: 787 }}>
        <Table
          sx={{
            //  width: '100%',
            // maxWidth: 60,
            bgcolor: "background.paper",
            //position: 'relative',
            overflow: "auto",
            maxHeight: 768,
            // minHeight: 767,
            //  minWidth: 140,
          }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Cavalo</Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title="Unidade de Carga">
                  <Typography className={classes.cabecalhos}>U.C</Typography>
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>Loja</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.cabecalhos}>Pallet</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography className={classes.cabecalhos}>T</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography className={classes.cabecalhos}>Perfil</Typography>
              </TableCell>

              <TableCell align="left">
                <Typography className={classes.cabecalhos}>
                  Tempo Loja
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metodoprincipal?.map((item) => (
              <TableRow>
                <TableCell component="th" scope="row" align="center">
                  <Typography
                    className="  textoareaplaca"
                    style={{
                      fontSize: 15,
                      marginLeft: -15,
                    }}
                  >
                    {item.fkstatusentregas.fkviagens == null
                      ? 0
                      : item.fkstatusentregas.fkviagens.fkveiculotrator.placa}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    className="textoareanfrota"
                    style={{ fontSize: 20, marginLeft: -10 }}
                  >
                    {item.fkstatusentregas.fkviagens == null
                      ? 0
                      : item.fkstatusentregas.fkviagens.fkveiculo.nfrota}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography
                    className="textoarealoja"
                    style={{
                      fontSize: 14,
                      margin: 1,
                      textAlign: "center",
                      marginLeft: -10,
                    }}
                  >
                    {item.fkentregas.fkdestino.lojas}
                  </Typography>
                </TableCell>

                <TableCell align="center">
                  <Typography
                    className="textoarepallet"
                    style={{
                      fontSize: 20,
                      marginLeft: -10,
                    }}
                  >
                    {item.fkentregas.qtdpallet}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    style={{
                      fontSize: 14,
                      marginLeft: -30,
                    }}
                  >
                    <FaTemperatureLow
                      style={{
                        fontSize: 10,
                        color: "red",
                      }}
                    />
                    {item.temperatura}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  {(() => {
                    let perfil = item.fkentregas.perfil;

                    return (
                      <Typography
                        sx={{ fontSize: 40 }}
                        style={{
                          marginLeft: -15,
                        }}
                      >
                        {perfil == "Horti" ? (
                          <GiGrapes
                            style={{
                              fontSize: 22,
                              color: "#194d33",
                            }}
                          />
                        ) : perfil == "Panificacao Seca" ? (
                          <GiSlicedBread
                            style={{
                              fontSize: 22,
                              color: "#fcdc00",
                            }}
                          />
                        ) : perfil == "Panificacao Frios" ? (
                          <GiSlicedBread
                            style={{
                              fontSize: 22,
                              color: "#B4C4BC",
                            }}
                          />
                        ) : perfil == "Frios" ? (
                          <AcUnitIcon
                            style={{
                              fontSize: 22,
                              color: "#0d47a1",
                            }}
                          />
                        ) : (
                          <GiCube
                            style={{
                              fontSize: 22,
                              color: "#fcb900",
                            }}
                          />
                        )}
                      </Typography>
                    );
                  })()}
                </TableCell>
                <TableCell align="left">
                  {(() => {
                    //hora atual

                    let horsea = new Date();
                    let [diaa, mesa, anoa] = new Date()
                      .toLocaleDateString("pt-BR")
                      .split("/");
                    let formatarhoraatual =
                      diaa +
                      "-" +
                      mesa +
                      "-" +
                      anoa +
                      " " +
                      horsea.getHours() +
                      ":" +
                      horsea.getMinutes() +
                      ":" +
                      horsea.getSeconds();

                    //data criada

                    const format1 = "DD-MM-YYYY HH:mm:ss";
                    let horseteste = new Date(item.dtchegadaloja);

                    let formatardataapi = moment
                      .utc(horseteste)
                      .format(format1);

                    //teste de calculo tempo de cd
                    var ms = moment(
                      formatarhoraatual,
                      "DD/MM/YYYY HH:mm:ss"
                    ).diff(moment(formatardataapi, "DD/MM/YYYY HH:mm:ss"));
                    var d = moment.duration(ms);
                    var tempo =
                      Math.floor(d.asHours()) + moment.utc(ms).format(":mm");

                    //tempo baixo 7 hrs
                    var parametro1 = moment(
                      "20220525 13:00:00",
                      "YYYYMMDD HH:mm:ss"
                    ).diff(moment("20220525 12:10:00", "YYYYMMDD HH:mm:ss"));
                    var dados1 = moment.duration(parametro1);
                    var tempoparametro1 =
                      Math.floor(dados1.asHours()) +
                      moment.utc(parametro1).format(":mm");

                    //tempo baixo  hrs
                    var parametro2 = moment(
                      "20170101 13:30:00",
                      "YYYYMMDD HH:mm:ss"
                    ).diff(moment("20170101 13:00:00", "YYYYMMDD HH:mm:ss"));
                    var dados2 = moment.duration(parametro2);
                    var tempoparametro2 =
                      Math.floor(dados2.asHours()) +
                      moment.utc(parametro2).format(":mm");

                    //tempo baixo  hrs
                    var parametro3 = moment(
                      "20170101 20:00:00",
                      "YYYYMMDD HH:mm:ss"
                    ).diff(moment("20170101 15:00:00", "YYYYMMDD HH:mm:ss"));
                    var dados3 = moment.duration(parametro3);
                    var tempoparametro3 =
                      Math.floor(dados3.asHours()) +
                      moment.utc(parametro3).format(":mm");

                    if (tempo > tempoparametro1) {
                      return (
                        <Typography
                          className="tempocritico"
                          style={{ fontSize: 18, marginLeft: -30 }}
                        >
                          {tempo}
                        </Typography>
                      );
                    } else if (
                      tempo > tempoparametro3 &&
                      tempo < tempoparametro2
                    ) {
                      return (
                        <Typography
                          className="tempomedio"
                          style={{ fontSize: 18, marginLeft: -30 }}
                        >
                          {tempo}
                        </Typography>
                      );
                    } else if (tempo < tempoparametro3) {
                      return (
                        <Typography
                          className="tempobaixo"
                          style={{ fontSize: 18, marginLeft: -30 }}
                        >
                          {tempo}
                        </Typography>
                      );
                    }
                  })()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
